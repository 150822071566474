
<template>
    <div id="table-demo">
      <vx-card id="payment-table-loading" class="vs-con-loading__container">
          <vs-table :data="pageData.data" class="mb-4">
             <template slot="header">
              <div class="md:flex py-2 md:py-4 w-full">
                <h5 class="flex flex-grow items-center mb-2 md:mb-0">
                  <feather-icon icon="ArchiveIcon" class="w-5 h-5 mr-1"></feather-icon>
                  <span> Payments Recieved </span>
                </h5>
                 <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
                v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
              </div>
            </template>
            <template slot="thead">
              <vs-th>CustEmail</vs-th>
              <vs-th>Amount</vs-th>
              <vs-th>Date</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{tr.metadata.customer_email}}
                </vs-td>
                <vs-td>
                  <span class="uppercase">{{tr.currency}}</span>{{tr.paid_amount | money_format}}
                </vs-td>
                <vs-td style="min-width:9rem;">
                  {{tr.created_at | date_ago}}
                </vs-td>

                <template slot="expand">
                  <div class="con-expand-users w-full p-3">
                    <vs-list>
                      <vs-list-item  title="Method of Payment" :subtitle="`${tr.metadata.card_brand} ${tr.metadata.card}`"></vs-list-item>
                      <vs-list-item title="Buyer Email" :subtitle="tr.metadata.customer_email"></vs-list-item>
                      <vs-list-item title="Transaction Ref" :subtitle="tr.transref"></vs-list-item>
                      <vs-list-item title="Currency" :subtitle="tr.currency"></vs-list-item>
                      <vs-list-item title="Description" :subtitle="tr.description"></vs-list-item>
                    </vs-list>
                    <div class="con-btns-user py-3 flex items-center justify-between">
                      <div class="flex">
                        <vs-button type="border" size="small" icon-pack="feather" icon="icon-printer" color="success" @click="printPayment(tr.id)" class="mr-2"></vs-button>
                        <a :href="tr.metadata.receipt_url" class="ml-auto mt-2" target="_blank">View receipt</a>
                      </div>
                    </div>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            :total="pageData.last_page || 1"
            :max="7"
            v-model="currentPage" />
        </vx-card>
         <vs-popup class="holamundo" @close="initializeAll()" title="Invoice" :active.sync="popupInvoice">
          <form>
            <div class="vx-row">
              <div v-if="awaitingPrint" class="vx-col w-full" id="printableDIV">
                <vx-card :title="'Total Amount Paid: $'+ awaitingPrint.paid_amount +' USD'">
                  <p class="py-2"><span class="font-bold"> Payment Method:</span> {{awaitingPrint.metadata.card_brand}} {{awaitingPrint.metadata.card}}</p>
                  <p class="py-2"><span class="font-bold"> Transaction Ref:</span> {{awaitingPrint.transref}}</p>
                  <p class="py-2"><span class="font-bold"> Currency:</span> {{awaitingPrint.currency}}</p>
                  <p class="py-2"><span class="font-bold"> Description:</span> {{awaitingPrint.description}}</p>
                  <p class="py-2"><span class="font-bold"> Buyer Email:</span> {{awaitingPrint.metadata.customer_email}}</p>
                  <p class="py-2"><span class="font-bold"> Date: </span> {{awaitingPrint.created_at | date(true)}}</p>
                </vx-card>
              </div>
            </div>
            <vs-button class="ml-auto mt-2" @click="acceptPrint()" size="small">Print</vs-button>
          </form>
        </vs-popup>
    </div>
</template>

<script>
import _ from 'lodash';

export default{
  data() {
    return {
      pageData: {
        data:[]
      },
      searchQuery: "",
      awaitingPrint: null,
      popupInvoice: false,
    }
  },
  computed:{
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchPayments(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchPayments(1, val)
      }
    },
  },
  methods: {
    fetchPayments(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#payment-table-loading', color: "#444", type: "sound"})
      this.$http.get(`/payments?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.$vs.loading.close('#payment-table-loading > .con-vs-loading')
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchPayments()
    }, 2000),
    printPayment(payId){
      this.awaitingPrint = this.payments.find((payment)=> payment.id == payId )
      this.popupInvoice = true
    },
    initializeAll(){
      this.awaitingPrint = null
    },
    acceptPrint(){
      document.querySelector('body').classList.toggle('onPrintContent')
      window.print();
      document.querySelector('body').classList.toggle('onPrintContent')
    },
  },
  mounted(){
    this.fetchPayments()
  },
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}

@media print {
  .onPrintContent{
    background: #fff !important;
  }
  .onPrintContent * {
    visibility: hidden;
  }
  .onPrintContent #printableDIV{
      position: absolute;
      top: 50px;
      left: 10px;
      box-shadow: none;
  }
  .onPrintContent #printableDIV *{
    visibility: visible !important;
  }
}
</style>
